import moment from 'moment';
import _ from 'lodash';
import { formatMonthDateTime, get } from '~/utils/date.util';
import type { IContainerBillOfLadingTrackingHit } from '~/types/single-container';

export const CONTAINER_STATUS_CODES_LIST_V2 = [
  'UPLOADED',
  'BL_NOT_FOUND',
  'CNTR_NOT_FOUND',
  'INVALID_BL_NO',
  'CNTR_NOT_MAPPED_TO_BL',
  'EARLY_JOURNEY',
  'JOURNEY_IN_PROGRESS',
  'JOURNEY_COMPLETED',
];
export const parseContainerBookmark = (container: Partial<IContainerBillOfLadingTrackingHit>): any => {
  const parsedContainer = {
    bookmark_id: container.id,
    masterReport: container,
    // container & booking
    containerNumber: container.cntr_no,
    blNumber: container.bl_no,
    poNumber: container.po_number,
    // carrier &vessel
    carrierScac: container.carrier_no, // scac or derived scac from BL
    vesselNames: getVesselNames(container), // vessel names
    // journey
    pol: container.pol,
    pod: container.pod,
    tsCodes: container?.transhipment_port_codes || [], // transhipment ports
    // status
    isArrived: container.arrived,
    status: container.status,
    // note:arrivalStatusCode=late, early, ontime and same as status_code
    arrivalStatusCode: container.arrival_status_code,
    // note:container_status_code=JOURNEY_IN_PROGRESS, JOURNEY_COMPLETED, JOURNEY_NOT_STARTED
    containerStatusCode: container.container_status_code,
    podArrivalStatus: container.arrival_time, // Delay, On Time, Early by days
    containerStatus: container.container_status, // Success,Container Uploaded, Container Not Mapped with BL...
    petaDelta: container.eta_delta, // initialEta - portcastEta
    carrierDelta: container.eta_delta, // initialEta - carrierEta

    // ETA & ATA timestamps
    initialEta: getInitialCeta(container), // (original)
    carrierEta: convertEpochToDateTime(container.pod_schedule_estimated_arrival_lt), // (scheduled/latest)
    portcastEta: getPeta(container), // (portcast)
    actualArrivalAIS: convertEpochToDateTime(container.pod_ais_actual_arrival_lt), // (actual) Arrival by AIS
    // Latest Event
    latestEvent: container.latest_actual_container_event_raw,
    latestEventDateTime: convertEpochToDateTime(container.latest_actual_container_event_timestamp_lt),

    // created and updated
    created: convertEpochToDateTime(container.created),
    updated: convertEpochToDateTime(container.updated),

    transhipmentPortNames: container.transhipment_port_names,
    polName: container.pol_name,
    podName: container.pod_name,
    arrivalStatus: container.arrival_status,
    lastVesselImo: container.last_vessel_imo,
    vesselImos: container.vessel_imos,
    lastVesselName: container.last_vessel_name,
    orgId: container.org_id,
    deleted: container.deleted,
    cachedBillOfLadingBookmarkId: container.cached_bill_of_lading_bookmark_id,
    groupByCount: container.group_children_ids && container.group_children_ids.length > 0
      ? container.group_children_ids.length
      : 0,
    groupByChildrenIds: container.group_children_ids && container.group_children_ids.length > 0
      ? container.group_children_ids
      : [],
    co2_emissions: container.co2_emissions,
    delayReasonCodes: container.delay_reason_codes || [],
  };

  return { ...parsedContainer };
};

const getPeta = (container: any) => {
  if (container.pod_portcast_predicted_arrival_lt) {
    return convertEpochToDateTime(container.pod_portcast_predicted_arrival_lt);
  }
  return '';
};
const getInitialCeta = (container: any) => {
  if (container.arrived) {
    return convertEpochToDateTime(container.pod_carrier_actual_arrival_lt);
  }
  else {
    if (container?.original_ceta_lt) {
      return convertEpochToDateTime(container.original_ceta_lt);
    }
    else if (container?.pod_carrier_estimated_arrival) {
      return convertEpochToDateTime(container.pod_carrier_estimated_arrival_lt);
    }
  }
  return '';
};
const getVesselNames = (container: any): string => {
  if (!container.vessel_names || container.vessel_names?.length === 0) return 'Vessel Not Confirmed';

  const vesselNames: string[] = [];
  container.vessel_names.forEach((vesselName: string) => {
    if (vesselName) {
      vesselNames.push(vesselName === '-' ? 'Vessel Not Confirmed' : vesselName);
    }
  });
  return vesselNames.join(', ');
};

export const convertEpochToDateTime = (epoch: number | any): string => {
  if (!epoch) return '';
  return formatMonthDateTime(epoch);
};

const CONTAINER_PAGE_TABS: any = {
  late: 'late',
  active: 'active',
  arrived: 'arrived',
  new: 'new',
  all: 'all',
  bookmarked: 'bookmarked',
  early: 'early',
  on_time: 'on_time',
};

const getDefaultSortByFilter = (query: any) => {
  if (query.sortBy) {
    return query.sortBy;
  }
  if ([
    CONTAINER_PAGE_TABS.active,
    CONTAINER_PAGE_TABS.late,
  ].includes(query.tab)) {
    return 'pod_portcast_predicted_arrival_lt:desc';
  }
  if ([
    CONTAINER_PAGE_TABS.new,
    CONTAINER_PAGE_TABS.all,
    CONTAINER_PAGE_TABS.bookmarked,
  ].includes(query.tab)) {
    return 'created:desc';
  }
  if (CONTAINER_PAGE_TABS.arrived === query.tab) {
    return 'pod_actual_arrival_lt:desc';
  }
  return 'created:desc';
};

export const prepareQueryParamsForContainerRequest
    = (query: any, customOrgFieldsFlatten: any[] = [], ids: any = '', isDownload: boolean = false): any => {
      const queryParams: any = {
        q: '*',
        page: query.page || 1,
        per_page: query.perPage || 20,
        query_by: 'bill_of_lading_bookmark_id,cntr_no,bl_no,carrier_no,pol,pol_name,pod,pod_name,arrival_status_code,vessel_names,vessel_imos,transhipment_port_codes,transhipment_port_names,po_number,booking_number,internal_ref_number,tms_reference_number,contents,supplier,importer,consignee,customer',
        filter_by: 'deleted:=false&&system_deleted:=false',
        sort_by: getDefaultSortByFilter(query),
        group_by: query.groupBy ? query.groupBy : '',
        num_typos: query?.length < 8 ? 1 : 0,
      };

      if (query.q) {
        queryParams.q = query.q;
      }
      if (query.id) {
        queryParams.filter_by = `id:[${query.id}]`;
        return Object.keys(queryParams)
          .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
          .join('&');
      }
      if (query.tab) {
        if (query.tab === 'DELETED') {
          queryParams.filter_by = 'deleted:true&&system_deleted:=false';
        }
        else if (query.tab === 'NEW') {
          queryParams.filter_by += `&&created:[${moment().subtract(1, 'days').unix()}..${moment().unix()}]`;
        }
        else if (query.tab === 'LATE') {
          queryParams.filter_by += '&&arrival_status_code:[late]';
        }
        else if (query.tab === 'ARRIVED') {
          queryParams.filter_by += '&&arrived:[true]';
        }
        else if (query.tab === 'ERROR') {
          // todo: add error status code
          queryParams.filter_by += 'system_deleted:true';
        }
        else if (query.tab === 'ALL' || query.tab === 'ACTIVE') {
          // todo: find what is difference between all and active
          queryParams.filter_by = 'deleted:=false&&system_deleted:=false';
        }
      }

      if (validateAndTrimString(query.tab_type) && query.tab_type.toLowerCase() === 'error') {
        queryParams.filter_by = 'deleted:false && status:[CONTAINER_NOT_FOUND,NO_DATA_FROM_PROVIDER,CONTAINER_NOT_MAPPED_WITH_BL,TARGET_PORT_NOT_IN_ROTATION] && system_deleted:=false';
      }
      if (validateAndTrimString(query.tab_type) && query.tab_type.toLowerCase() === 'deleted') {
        queryParams.filter_by = 'deleted:true && system_deleted:=false';
      }
      if (filterAndJoinCommaSeparated(query.carrier)) {
        queryParams.filter_by += `&&carrier_no:[${filterAndJoinCommaSeparated(query.carrier)}]`;
      }
      if (filterAndJoinCommaSeparated(query.vessel)) {
        queryParams.filter_by += `&&vessel_imos:[${filterAndJoinCommaSeparated(query.vessel)}]`;
      }
      if (filterAndJoinCommaSeparated(query.status)) {
        queryParams.filter_by += `&&arrival_status_code:[${filterAndJoinCommaSeparated(query.status)}]`;
      }
      if (filterAndJoinCommaSeparated(query.poNumber)) {
        queryParams.filter_by += `&&po_number:[${query.poNumber}]`;
      }
      if (filterAndJoinCommaSeparated(query.blNumber)) {
        queryParams.filter_by += `&&bl_no:[${query.blNumber}]`;
      }

      if (filterAndJoinCommaSeparated(query.pol)) {
        queryParams.filter_by += `&&pol:[${query.pol}]`;
      }
      if (filterAndJoinCommaSeparated(query.transhipment)) {
        queryParams.filter_by += `&&transhipment:[${query.transhipment}]`;
      }
      if (filterAndJoinCommaSeparated(query.pod)) {
        queryParams.filter_by += `&&pod:[${query.pod}]`;
      }
      if (filterAndJoinCommaSeparated(query.pol_country)) {
        queryParams.filter_by += `&&pol_country:[${query.pol_country}]`;
      }
      if (filterAndJoinCommaSeparated(query.pod_country)) {
        queryParams.filter_by += `&&pod_country:[${query.pod_country}]`;
      }

      if (validateAndTrimString(query.lateStatus)) {
        queryParams.filter_by += `&&arrival_status_code:[late]&&pod_arrival_prediction_vs_original_etd_delta:${validateAndTrimString(query.lateStatus)}`;
      }

      if (query.actual) {
        const date = splitDates(query.actual);
        if (date.length > 1) {
          const to = get(date[0]).startOf('day').unix();
          const from = get(date[1]).endOf('day').unix();
          queryParams.filter_by += ` && pod_ais_actual_arrival:[${to}..${from}]`;
        }
      }
      if (query.predicted) {
        const date = splitDates(query.predicted);
        if (date.length > 1) {
          const to = get(date[0]).startOf('day').unix();
          const from = get(date[1]).endOf('day').unix();
          queryParams.filter_by += ` && pod_portcast_predicted_arrival:[${to}..${from}]`;
        }
      }
      if (query.scheduled) {
        const date = splitDates(query.scheduled);
        if (date.length > 1) {
          const to = get(date[0]).startOf('day').unix();
          const from = get(date[1]).endOf('day').unix();
          queryParams.filter_by += ` && pod_carrier_estimated_arrival:[${to}..${from}]`;
        }
      }
      if (query.created) {
        const date = splitDates(query.created);
        if (date.length > 1) {
          const to = get(date[0]).startOf('day').unix();
          const from = get(date[1]).endOf('day').unix();
          queryParams.filter_by += ` && created:[${to}..${from}]`;
        }
      }
      if (query.updated) {
        const date = splitDates(query.updated);
        if (date.length > 1) {
          const to = get(date[0]).startOf('day').unix();
          const from = get(date[1]).endOf('day').unix();
          queryParams.filter_by += ` && updated:[${to}..${from}]`;
        }
      }

      if (filterAndJoinCommaSeparated(query.error)) {
        queryParams.filter_by += ` && status:[${query.error}]`;
      }

      if (filterAndJoinCommaSeparated(query.latestEvents)) {
        query.latestEvents.split(',').forEach((latestEvent: string) => {
          queryParams.filter_by += ` && ${latestEvent}:>0`;
        });
      }

      if (validateAndTrimString(query.po_number)) {
        queryParams.filter_by += ` && po_number:[${validateAndTrimString(query.po_number)}]`;
      }
      if (validateAndTrimString(query.booking_number)) {
        queryParams.filter_by += ` && booking_number:[${validateAndTrimString(query.booking_number)}]`;
      }
      if (validateAndTrimString(query.internal_ref_number)) {
        queryParams.filter_by += ` && internal_ref_number:[${validateAndTrimString(query.internal_ref_number)}]`;
      }
      if (validateAndTrimString(query.tms_reference_number)) {
        queryParams.filter_by += ` && tms_reference_number:[${validateAndTrimString(query.tms_reference_number)}]`;
      }
      if (validateAndTrimString(query.contents)) {
        queryParams.filter_by += ` && contents:[${validateAndTrimString(query.contents)}]`;
      }
      if (validateAndTrimString(query.importer)) {
        queryParams.filter_by += ` && importer:[${validateAndTrimString(query.importer)}]`;
      }
      if (validateAndTrimString(query.supplier)) {
        queryParams.filter_by += ` && supplier:[${validateAndTrimString(query.supplier)}]`;
      }
      if (validateAndTrimString(query.consignee)) {
        queryParams.filter_by += ` && consignee:[${validateAndTrimString(query.consignee)}]`;
      }

      const keys = Object.keys(query);
      keys.forEach((key: any) => {
        const isCustomFieldExists = customOrgFieldsFlatten.find((field: any) => `custom_field_${field.label}` === key);
        const filterValue = query[key];
        if (key.includes('custom_field_') && filterValue && isCustomFieldExists) {
          queryParams.filter_by += ` && (${getFilterQuery(filterValue, isCustomFieldExists.label)})`;
        }
      });
      if (ids) {
        queryParams.filter_by += ids;
      }

      if (isDownload) {
        delete queryParams.page;
        delete queryParams.per_page;
        delete queryParams.sort_by;
        delete queryParams.group_by;
      }

      const queryParamString = Object.keys(queryParams)
        .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      return (queryParamString);
    };

const splitDates = (dates: any) => {
  return dates?.toString().split(',') || [];
};

const getFilterQuery = (filterValue: string, label: string) => {
  const filter = filterValue.split(',').map((value: string) => {
    const customFieldLabel = `custom_field_${label}`;
    return `${customFieldLabel}:\`${value}\``;
  });
  return filter.join(' || ');
};

export const prepareQueryParamsFromFilters = (filters: any = {}, customOrgFieldsFlatten: any[] = []): any => {
  const queryParams: any = {};
  if (joinNonEmptyItems(filters.status)) {
    queryParams.status = joinNonEmptyItems(filters.status);
  }
  if (validateAndTrimString(filters.lateStatus)) {
    queryParams.lateStatus = validateAndTrimString(filters?.lateStatus);
  }
  if (joinNonEmptyItems(filters.carrier)) {
    queryParams.carrier = joinNonEmptyItems(filters.carrier);
  }
  if (joinNonEmptyItems(filters.vessel)) {
    queryParams.vessel = joinNonEmptyItems(filters.vessel);
  }
  if (joinNonEmptyItems(filters.actual)) {
    queryParams.actual = joinNonEmptyItems(filters.actual);
  }
  if (joinNonEmptyItems(filters.predicted)) {
    queryParams.predicted = joinNonEmptyItems(filters.predicted);
  }
  if (joinNonEmptyItems(filters.scheduled)) {
    queryParams.scheduled = joinNonEmptyItems(filters.scheduled);
  }
  if (joinNonEmptyItems(filters.created)) {
    queryParams.created = joinNonEmptyItems(filters.created);
  }
  if (joinNonEmptyItems(filters.updated)) {
    queryParams.updated = joinNonEmptyItems(filters.updated);
  }
  if (joinNonEmptyItems(filters.error)) {
    queryParams.error = joinNonEmptyItems(filters.error);
  }
  if (joinNonEmptyItems(filters.latestEvents)) {
    queryParams.latestEvents = joinNonEmptyItems(filters.latestEvents);
  }
  if (joinNonEmptyItems(filters.pol)) {
    queryParams.pol = joinNonEmptyItems(filters.pol);
  }
  if (joinNonEmptyItems(filters.transhipment)) {
    queryParams.transhipment = joinNonEmptyItems(filters.transhipment);
  }
  if (joinNonEmptyItems(filters.pod)) {
    queryParams.pod = joinNonEmptyItems(filters.pod);
  }
  if (joinNonEmptyItems(filters.pol_country)) {
    queryParams.pol_country = joinNonEmptyItems(filters.pol_country);
  }
  if (joinNonEmptyItems(filters.pod_country)) {
    queryParams.pod_country = joinNonEmptyItems(filters.pod_country);
  }
  if (filters.po_number) {
    queryParams.po_number = filters.po_number;
  }
  if (filters.booking_number) {
    queryParams.booking_number = filters.booking_number;
  }
  if (filters.internal_ref_number) {
    queryParams.internal_ref_number = filters.internal_ref_number;
  }
  if (filters.tms_reference_number) {
    queryParams.tms_reference_number = filters.tms_reference_number;
  }
  if (filters.contents) {
    queryParams.contents = filters.contents;
  }
  if (filters.importer) {
    queryParams.importer = filters.importer;
  }
  if (filters.supplier) {
    queryParams.supplier = filters.supplier;
  }
  if (filters.consignee) {
    queryParams.consignee = filters.consignee;
  }
  if (filters.tab_type) {
    queryParams.tab_type = filters.tab_type;
  }
  if (filters.sortBy) {
    queryParams.sortBy = filters.sortBy;
  }
  if (filters.groupBy) {
    queryParams.groupBy = filters.groupBy;
  }
  if (customOrgFieldsFlatten && customOrgFieldsFlatten.length) {
    const keys = Object.keys(filters);
    keys.forEach((key: any) => {
      const isCustomFieldExists = customOrgFieldsFlatten.find((field: any) =>
        `custom_field_${field.label}` === key);
      if (key.includes('custom_field_') && filters[key] && isCustomFieldExists) {
        queryParams[key] = filters[key] || '';
      }
    });
  }
  return queryParams;
};

export const prepareFiltersFromQueryParams = (_filters: any): any => {
  return {
    status: _filters?.status?.split(',') || [],
    lateStatus: _filters?.lateStatus || '',
    carrier: _filters?.carrier?.split(',') || [],
    vessel: _filters?.vessel?.split(',') || [],
    actual: _filters?.actual?.split(',') || [],
    predicted: _filters?.predicted?.split(',') || [],
    scheduled: _filters?.scheduled?.split(',') || [],
    created: _filters?.created?.split(',') || [],
    updated: _filters?.updated?.split(',') || [],
    error: _filters?.error?.split(',') || [],
    latestEvents: _filters?.latestEvents?.split(',') || [],
    po_number: _filters?.po_number || '',
    booking_number: _filters?.booking_number || '',
    internal_ref_number: _filters?.internal_ref_number || '',
    tms_reference_number: _filters?.tms_reference_number || '',
    contents: _filters?.contents || '',
    importer: _filters?.importer || '',
    supplier: _filters?.supplier || '',
    consignee: _filters?.consignee || '',
    tab_type: _filters?.tab_type || '',
    sortBy: _filters?.sortBy || '',
    groupBy: _filters?.groupBy || '',
    pol: _filters?.pol || [],
    transhipment: _filters?.transhipment || [],
    pod: _filters?.pod || [],
    pol_country: _filters?.pol_country || [],
    pod_country: _filters?.pod_country || [],
  };
};

const joinNonEmptyItems = (data: any): string | null => {
  const isValidArray = data && Array.isArray(data) && data.length > 0 && data.some((item: any) => item);
  if (!isValidArray) return null;
  return data.filter((item: any) => item).join(',');
};

const validateAndTrimString = (data: any): string | null => {
  const isString = typeof data === 'string';
  if (!isString) return null;
  const dataString = data?.trim();
  return dataString && dataString.length > 0 ? dataString : null;
};

const filterAndJoinCommaSeparated = (data: any): string | null => {
  const isString = typeof data === 'string';
  if (!isString) return null;
  const validArray = data.split(',').filter((item: any) => item);
  if (!validArray.length) return null;
  return validArray.join(',');
};

export const getArrivedDelta = (bookmark: any) => {
  const initial = bookmark?.initialEta;
  const actual = bookmark?.actualArrivalAIS;

  if (initial && actual) {
    // Parse the dates
    const initialArrivedDate: any = new Date(initial);
    const actualArrivedDate: any = new Date(actual);

    // Calculate the difference in milliseconds
    const deltaInMillis = actualArrivedDate - initialArrivedDate;

    // Convert milliseconds to days
    const deltaInDays = deltaInMillis / (1000 * 60 * 60 * 24);

    // Check if the delta is within the same day
    if (deltaInDays < 1 && initialArrivedDate.toDateString() === actualArrivedDate.toDateString()) {
      return 'On Time';
    }
    else if (deltaInDays < 0) {
      return `Early - ${Math.abs(Math.floor(deltaInDays))} day(s)`;
    }
    else {
      return `Delay - ${Math.ceil(deltaInDays)} day(s)`;
    }
  }
  return '';
};

export const getDelayReasons = (container: any) => {
  if (container.isArrived) {
    return '';
  }
  if (container.delayReasons) {
    return `(${container.delayReasons})`;
  }
  return '';
};

export const containerFiltersNames = (t: any) => {
  return {
    'bl_no': t('common.billNumber'),
    'groupBy': t('containerPage.groupBy'),
    'sortBy': t('containerPage.sortBy'),
    'latestEvents': t('common.latestEvents'),
    'org_id': t('common.masterOrg'),
    'On_time': t('common.onTime'),
    'vessel_imos,pol,pod': t('containerPage.vesselVoyage'),
    'pod_portcast_predicted_arrival_lt:asc': t('containerPage.arrivalSortByItems.pod_portcast_predicted_arrival_lt_asc'),
    'pod_portcast_predicted_arrival_lt:desc': t('containerPage.arrivalSortByItems.pod_portcast_predicted_arrival_lt_desc'),
    'pod_actual_arrival_lt:asc': t('containerPage.arrivalSortByItems.pod_actual_arrival_lt_asc'),
    'pod_actual_arrival_lt:desc': t('containerPage.arrivalSortByItems.pod_actual_arrival_lt_desc'),
    'created:asc': t('containerPage.addedOnSortByItems.created_asc'),
    'created:desc': t('containerPage.addedOnSortByItems.created_desc'),
    'cntr_event_DEPART_AT_POT_actual_lt': t('common.emptyGateOutAtPOL'),
    'cntr_event_GATEIN_AT_POL_actual_lt': t('common.gateInFullAtPOL'),
    'cntr_event_LOAD_AT_POL_actual_lt': t('common.loadedAtPOL'),
    'cntr_event_DEPART_AT_POL_actual_lt': t('common.departedFromPOL'),
    'cntr_event_ARRIVE_AT_POD_actual_lt': t('common.arrivalAtPOD'),
    'cntr_event_UNLOAD_AT_POD_actual_lt': t('common.dischargedAtPOD'),
    'cntr_event_GATEOUT_AT_POD_actual_lt': t('common.gateOutFullAtPOD'),
    'cntr_event_RETURN_AT_POD_actual_lt': t('common.emptyReturnAtPOD'),
    'NON_OPTIMAL_ROUTE': t('common.routeChange'),
    'ANCH_STAY': t('common.anchorageDelay'),
    'DELAY_AT_PORT_STAY': t('common.portStay'),
    'NO_DATA_FROM_PROVIDER': t('common.carrierDataMissing'),
    'NO_DATA_RECEIVED_FROM_CARRIER': t('common.carrierDataMissing'),
    'CONTAINER_NOT_FOUND': t('common.statusList.CONTAINER_NOT_FOUND'),
    'CONTAINER_NOT_MAPPED_WITH_BL': t('common.invalidInput'),
    'WEATHER_ADVERSITY': t('common.weatherAdversity'),
    'TARGET_PORT_NOT_IN_ROTATION': t('common.delayAtUpcomingPort'),
    'UNKNOWN_ERROR': t('common.unknownError'),
    'po_number': t('common.poNumber'),
    'booking_number': t('containerPage.bookingNumber'),
    'internal_ref_number': t('containerPage.internalRefNumber'),
    'tms_reference_number': t('common.tmsRefNumber'),
    'lateStatus': t('common.lateStatus'),
    'vessel': t('containerPage.vessel'),
    'carrier': t('containerPage.carrier'),
    'transhipment': t('common.transhipment'),
    'actual': t('containerPage.actual'),
    'predicted': t('containerPage.predicted'),
    'scheduled': t('containerPage.scheduled'),
    'created': t('common.created'),
    'updated': t('common.updated'),
    'contents': t('containerPage.contents'),
    'importer': t('containerPage.importer'),
    'supplier': t('containerPage.supplier'),
    'consignee': t('containerPage.consignee'),
    'incidents': t('common.incidents'),
    'error': t('common.error'),
    'pol_country': t('common.pol_country'),
    'pod_country': t('common.pod_country'),
  };
};

export const isFiltersApplied = (totalFilters: any) => {
  const filters = Object.keys(totalFilters).filter(item => Array.isArray(totalFilters[item]) ? totalFilters[item].length > 0 : typeof totalFilters[item] === 'object' ? totalFilters[item]?.type : totalFilters[item]);
  return filters.length >= 1;
};

export const eventsList = [
  {
    id: 'cntr_event_DEPART_AT_POT_actual_lt',
    label: 'Empty Gate Out (at POL)',
  },
  {
    id: 'cntr_event_GATEIN_AT_POL_actual_lt',
    label: 'Gate In Full (at POL)',
  },
  {
    id: 'cntr_event_LOAD_AT_POL_actual_lt',
    label: 'Loaded (at POL)',
  },
  {
    id: 'cntr_event_DEPART_AT_POL_actual_lt',
    label: 'Departed (from POL)',
  },
  {
    id: 'cntr_event_ARRIVE_AT_POD_actual_lt',
    label: 'Arrival (at POD)',
  },
  {
    id: 'cntr_event_UNLOAD_AT_POD_actual_lt',
    label: 'Discharged (at POD)',
  },
  {
    id: 'cntr_event_GATEOUT_AT_POD_actual_lt',
    label: 'Gate Out Full (at POD)',
  },
  {
    id: 'cntr_event_RETURN_AT_POD_actual_lt',
    label: 'Empty Return (at POD)',
  },
];

export const errorList = (t: any) => {
  return [
    {
      label: t('common.carrierDataMissing'),
      id: 'NO_DATA_RECEIVED_FROM_CARRIER',
    },
    {
      label: t('common.invalidInput'),
      id: 'CONTAINER_NOT_MAPPED_WITH_BL',
    },
    {
      label: t('common.unknownError'),
      id: 'UNKNOWN_ERROR',
    },
  ];
};
const getFilterValues = (appliedGroupFilters: any) => {
  const filtersWithValues: any = {};

  for (const key in appliedGroupFilters) {
    if (appliedGroupFilters[key] !== null && appliedGroupFilters[key] !== '' && appliedGroupFilters[key].length !== 0) {
      filtersWithValues[key] = appliedGroupFilters[key];
    }
  }

  return filtersWithValues;
};

export const getSavedFilterName = (appliedGroupFilters: any, savedViews: Array<any>) => {
  const appliedGroupFiltersValues = getFilterValues(appliedGroupFilters);

  return savedViews?.find((item: any) => {
    const savedFilterValues = getFilterValues(item.filters);
    return _.isEqual(savedFilterValues, appliedGroupFiltersValues);
  });
};

export const defaultSavedFilterView = [
  {
    name: 'Error',
    defaultView: false,
    systemGenerated: true,
  },
  // {
  //   name: 'Deleted',
  //   defaultView: false,
  //   systemGenerated: true,
  // },
];

export const getContainerStatus = (bookmark: any = {}, t: any, _isPetaDisabled: boolean = false): any => {
  const { containerStatusCode, arrivalStatusCode } = bookmark;
  const deltaDays = Math.abs(
    bookmark?.masterReport?.pod_arrival_prediction_vs_original_etd_delta
    || bookmark?.masterReport?.pod_arrival_schedule_vs_original_etd_delta);
    // const deltaDays = Math.abs(!isPetaDisabled
    //   ? bookmark.masterReport.pod_arrival_prediction_vs_original_etd_delta
    //   : bookmark.masterReport.pod_arrival_schedule_vs_original_etd_delta);

  let data = { status: '', info: '', color: '' };
  if (arrivalStatusCode === 'arrived') {
    data = {
      status: t('common.arrived'),
      info: getArrivedDelta(bookmark),
      color: '',
    };
  }
  else if (arrivalStatusCode === 'late') { // lateByXDays
    data = {
      ...data,
      status: t('common.statusList.lateByXDays', {
        days: deltaDays,
      }),
    };
  }
  else if (arrivalStatusCode === 'early') { // earlyByXDays
    data = {
      ...data,
      status: t('common.statusList.earlyByXDays', {
        days: deltaDays,
      }),
    };
  }
  else if (arrivalStatusCode === 'ontime') {
    data = { ...data, status: t('common.onTime') };
  }
  else if (containerStatusCode && CONTAINER_STATUS_CODES_LIST_V2.includes(containerStatusCode)) {
    data = {
      status: t(`common.statusListV2.${containerStatusCode}.status`),
      info: t(`common.statusListV2.${containerStatusCode}.info`),
      color: t(`common.statusListV2.${containerStatusCode}.color`),
    };
  }
  return data;
};

export const getStatusColor = (container: any) => {
  if (container?.arrivalStatusCode === 'late') {
    return 'red';
  }
  if (container?.arrivalStatusCode === 'early') {
    return 'yellow';
  }
  if (container?.arrivalStatusCode === 'on_time') {
    return 'green';
  }
  if (container?.arrivalStatusCode === 'arrived') {
    return 'primary';
  }
  return 'black';
};

export const getSingleContainerETA = (data: any, isPetaEnabled: any = true) => {
  const initial = data.initial;
  const predicted = data.predicted;
  const latest = data.latest;
  const predictedText = data.predictedText;
  const plannedText = data.plannedText;
  const latestText = data.latestText;
  if (isPetaEnabled && predicted) {
    return {
      header: `${predictedText} - ${predicted}`,
      headerValue: `${predicted}`,
      subHeaderValue: `${initial}`,
      subHeader: `${plannedText} - ${initial}`,
    };
  }
  else {
    return {
      header: `${latestText} - ${latest}`,
      headerValue: `${latest}`,
      subHeaderValue: `${initial}`,
      subHeader: `${plannedText} - ${initial}`,
    };
  }
};
